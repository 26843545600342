import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import WidgetCard, { DarkModeProp } from "./WidgetCard";
import { widgetCardIcons } from "images/icons/auth-homepage-icons";
import { useIsDarkMode } from "hooks/use-media-query";

interface IProps {
    animationType: string;
    imagesrc: string;
}

interface isDisplayedProp {
    isDisplayed: boolean;
}

// search animation styled components
const searchInitialStyles = {
    opacity: "opacity: 0;",
    transform: "transform: translateY(30%);",
};
const searchFinalStyles = {
    opacity: "opacity: 1;",
    transform: "transform: translateY(0%);",
};

const keyframes1 = keyframes`
  5%, 33% {
    ${searchInitialStyles.opacity}${searchInitialStyles.transform}
  }
  21% {
    ${searchFinalStyles.opacity}${searchFinalStyles.transform}
  }
`;

const keyframes2 = keyframes`
  39%, 66% {
    ${searchInitialStyles.opacity}${searchInitialStyles.transform}
  }
  54.5% {
    ${searchFinalStyles.opacity}${searchFinalStyles.transform}
  }
`;

const keyframes3 = keyframes`
  72%, 100% {
    ${searchInitialStyles.opacity}${searchInitialStyles.transform}
  }
  88% {
    ${searchFinalStyles.opacity}${searchFinalStyles.transform}
  }
`;

const SearchCard = styled.div`
    position: absolute;
    opacity: 0;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    aspect-ratio: 2;
    width: 520px;
    @media (max-width: 1200px) {
        width: 400px;
    }
    @media (max-width: 992px) {
        width: 320px;
    }
    @media (max-width: 768px) {
        width: 235px;
    }
    @media (max-width: 576px) {
        width: 410px;
    }
    @media (max-width: 440px) {
        width: 300px;
    }
`;

const SearchCard1 = styled(SearchCard)`
    animation-name: ${keyframes1};
`;
const SearchCard2 = styled(SearchCard)`
    animation-name: ${keyframes2};
`;
const SearchCard3 = styled(SearchCard)`
    animation-name: ${keyframes3};
`;

// tag animation styled components
const Image = styled.img<DarkModeProp>`
    width: ${({ isDarkMode }) => (isDarkMode ? "66%" : "62%")};
    margin-top: ${({ isDarkMode }) => (isDarkMode ? "0.5%" : "0.7%")};
    margin-right: 1%;
`;

const CategoryContainer = styled.div<isDisplayedProp>`
    opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};
`;
const CardConatainer1 = styled.div<isDisplayedProp>`
    opacity: ${({ isDisplayed }) => (isDisplayed ? 0 : 1)};
    z-index: 2;
    margin-top: -27%;
`;
const CardConatainer2 = styled.div<isDisplayedProp>`
    opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};
`;

// pin animation styled components
const PinContainer = styled.div<isDisplayedProp>`
    z-index: 1;
    position: absolute;
    scale: 1.75;
    margin-left: 76%;
    opacity: ${({ isDisplayed }) => (isDisplayed ? 1 : 0)};
    @media (max-width: 1275px) {
        scale: 1.4;
        margin-left: 75%;
    }
    @media (max-width: 992px) {
        margin-top: -1.8%;
        margin-left: 74.5%;
    }
    @media (max-width: 768px) {
        scale: 1.1;
        margin-top: -1.8%;
        margin-left: 73.2%;
    }
    @media (max-width: 576px) {
        scale: 1.3;
        margin-top: -1%;
        margin-left: 75%;
    }
    @media (max-width: 440px) {
        scale: 1.3;
        margin-top: -1.5%;
        margin-left: 73.7%;
    }
`;

export default function WidgetAnimation(props: IProps) {
    // changing card order for pin animation
    const isDarkMode = useIsDarkMode();
    const [cardOrder, setCardOrder] = useState([3, 0, 1, 2]);
    const [toggleOrder, setToggleOrder] = useState(false);
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (toggleOrder) {
                setCardOrder([0, 3, 1, 2]);
            } else {
                setCardOrder([3, 0, 1, 2]);
            }
            setToggleOrder((prevToggleOrder) => !prevToggleOrder);
        }, 2500);

        return () => clearInterval(intervalId);
    }, [toggleOrder]);
    const isDisplayed = cardOrder[0] === 0;

    switch (props.animationType) {
        case "search":
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <SearchCard1>
                        <WidgetCard iconData={widgetCardIcons[0]} />
                    </SearchCard1>
                    <SearchCard2>
                        <WidgetCard iconData={widgetCardIcons[1]} />
                    </SearchCard2>
                    <SearchCard3>
                        <WidgetCard iconData={widgetCardIcons[2]} />
                    </SearchCard3>
                </div>
            );

        case "tag":
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        position: "absolute",
                        top: "18%",
                        height: "38%",
                        width: "100%",
                    }}
                >
                    <CategoryContainer
                    isDisplayed={isDisplayed}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <Image
                            isDarkMode={isDarkMode}
                            fetch-priority="high"
                            srcSet={props.imagesrc}
                            alt="category"
                        />
                    </CategoryContainer>

                    <CardConatainer2 isDisplayed={isDisplayed}>
                        <div style={{ marginTop: "5%" }}>
                            <WidgetCard iconData={widgetCardIcons[0]} />
                        </div>
                    </CardConatainer2>
                    <CardConatainer1 isDisplayed={isDisplayed}>
                        <div style={{ marginTop: "4%" }}>
                            <WidgetCard iconData={widgetCardIcons[3]} />
                        </div>

                        <div style={{ marginTop: "2%" }}>
                            <WidgetCard iconData={widgetCardIcons[2]} />
                        </div>

                        <div style={{ marginTop: "2%" }}>
                            <WidgetCard iconData={widgetCardIcons[1]} />
                        </div>
                    </CardConatainer1>
                </div>
            );

        case "pin":
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        position: "absolute",
                        top: "25%",
                        height: "48%",
                        width: "100%",
                    }}
                >
                    <PinContainer isDisplayed={isDisplayed}>
                        {widgetCardIcons[4].icon}
                    </PinContainer>
                    <WidgetCard iconData={widgetCardIcons[cardOrder[0]]} />
                    <WidgetCard iconData={widgetCardIcons[cardOrder[1]]} />
                    <WidgetCard iconData={widgetCardIcons[cardOrder[2]]} />
                </div>
            );

            case "share":
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            position: "absolute",
                            top: "25%",
                            height: "48%",
                            width: "100%",
                        }}
                    >
                        <PinContainer isDisplayed={isDisplayed}>
                            {widgetCardIcons[4].icon}
                        </PinContainer>
                        <WidgetCard iconData={widgetCardIcons[cardOrder[0]]} />
                        <WidgetCard iconData={widgetCardIcons[cardOrder[1]]} />
                        <WidgetCard iconData={widgetCardIcons[cardOrder[2]]} />
                    </div>
                );
    }
}

import { useIsDarkMode } from "hooks/use-media-query";
import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { LineAnimation, Logo, Paragraph, Title } from "./WidgetCard";
import { widgetCardIcons } from "images/icons/auth-homepage-icons";

export interface DarkModeProp {
    isDarkMode: boolean;
}

const NotesBackground = styled.div<DarkModeProp>`
    padding: 12px;
    border-radius: 4px;
    background: ${({ isDarkMode }) => (isDarkMode ? "#000000" : "#ffffff")};
`;

const CardBackground = styled.div<DarkModeProp>`
    position: relative;
    overflow: hidden;
    border-radius: 3%;
    background: ${({ isDarkMode }) => (isDarkMode ? "#181818" : "#F6F6F6")};
    @media (prefers-color-scheme: light) {
        box-shadow:
            0px 3.791px 5.32px 0px rgba(0, 0, 0, 0.09),
            0px 1.577px 2.214px 0px rgba(0, 0, 0, 0.13);
    }
`;

export default function NotesCard() {
    const isDarkMode = useIsDarkMode();
    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
                background: isDarkMode ? "#3B2A45" : "#EFDAFC",
                borderRadius: "10px",
                padding: "16px",
                margin: "0 16%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Row style={{ width: "100%" }}>
                <CardBackground
                    isDarkMode={isDarkMode}
                    style={{ paddingBottom: "12px" }}
                >
                    <LineAnimation style={{ marginLeft: "-8%" }} />

                    <Row
                        style={{
                            marginTop: "16px",
                            marginBottom: "16px",
                            flexWrap: "nowrap",
                        }}
                    >
                        <Col>
                            <Title>Ente</Title>
                            <Paragraph isDarkMode={isDarkMode}>
                                photos@ente.io
                            </Paragraph>
                        </Col>
                        <Col className="col-auto">
                            <Logo isIconEnte={true} invertLogoColor={false}>
                                {widgetCardIcons[0].icon}
                            </Logo>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ paddingRight: "0px" }}>
                            <Title style={{ scale: "1.2", marginLeft: "10%" }}>
                                475 074
                            </Title>
                        </Col>
                        <Col
                            className="col-auto"
                            style={{
                                display: "flex",
                                justifyContent: "start",
                                flexDirection: "column",
                                alignItems: "flex-end",
                                marginTop: "-2%",
                                paddingLeft: "0px",
                            }}
                        >
                            <Row style={{ justifyContent: "flex-end" }}>
                                <Paragraph isDarkMode={isDarkMode}>
                                    next
                                </Paragraph>
                            </Row>
                            <Row style={{ justifyContent: "flex-end" }}>
                                <Paragraph isDarkMode={isDarkMode}>
                                    456 789
                                </Paragraph>
                            </Row>
                        </Col>
                    </Row>
                </CardBackground>
            </Row>

            <Row
                style={{
                    marginTop: "8px",
                    width: "100%",
                }}
            >
                <NotesBackground isDarkMode={isDarkMode}>
                    <Row style={{ color: "grey", marginBottom: "8px" }}>
                        <Paragraph isDarkMode={isDarkMode}>Notes</Paragraph>
                    </Row>
                    <Row>
                        <Paragraph isDarkMode={isDarkMode}>
                            Primary photos account
                        </Paragraph>
                    </Row>
                </NotesBackground>
            </Row>
        </Container>
    );
}

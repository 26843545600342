import React from "react";
import { Container, Heading, IconContainer } from "./Backups";
import { AuthIcon, widgetCardIcons } from "images/icons/auth-homepage-icons";
import { useIsDarkMode } from "hooks/use-media-query";
import styled from "styled-components";
import { FluidImageData } from "types";
import TextShine from "./TextShine";
import WidgetCard from "./WidgetCard";
import Carousel from "./Carousel";
import TypingAnimation from "./TypingAnimation";
import WidgetAnimation from "./WidgetAnimation";
import ShareCard from "./ShareCard";
import NotesCard from "./NotesCard";

interface IProps {
    images: FluidImageData[];
    sectionIcon: AuthIcon;
}

const CardContainer = styled.div`
    width: 1275px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-block: 4%;

    @media (max-width: 1280px) {
        width: 960px;
    }

    @media (max-width: 992px) {
        width: 720px;
    }

    @media (max-width: 768px) {
        width: 540px;
    }

    @media (max-width: 576px) {
        width: 420px;
    }
    @media (max-width: 440px) {
        width: 300px;
    }
`;

export const CardStyle = styled.div`
    border-radius: 3%;
    background: radial-gradient(
        107.32% 141.42% at 0% 0%,
        rgba(38, 0, 56, 0.57) 0%,
        rgba(38, 0, 56, 0.2) 100%
    );
    box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.005) inset;
    border: 1px solid #ad00ff;

    @media (prefers-color-scheme: light) {
        border: 1px solid #e1a1ff;
        background: radial-gradient(
            107.32% 141.42% at 0% 0%,
            rgba(254, 252, 255, 0.57) 0%,
            rgba(228, 170, 255, 0.2) 100%
        );
        box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02);
    }
`;

const Card1 = styled(CardStyle)`
    width: 47.06%;
    margin-block: 2%;
    padding-bottom: 5%;
    @media (max-width: 576px) {
        margin-block: 6%;
        width: 100%;
    }
`;

const Card2 = styled(CardStyle)`
    width: 47.06%;
    margin-block: 2%;
    padding-bottom: 5%;
    @media (max-width: 576px) {
        margin-block: 6%;
        width: 100%;
    }
`;

const Card3 = styled(CardStyle)`
    width: 47.06%;
    padding-bottom: 5%;
    height: 620px;
    margin-block: 2%;
    @media (max-width: 576px) {
        margin-block: 6%;
        width: 100%;
        height: 420px;
    }
    @media (max-width: 992px) {
        height: 420px;
    }
    overflow: hidden;
`;

const Card4 = styled(CardStyle)`
    width: 47.06%;
    margin-block: 2%;
    padding-bottom: 5%;
    @media (max-width: 576px) {
        margin-block: 6%;
        width: 100%;
    }
    overflow: hidden;
`;

const CardHeading = styled(Heading)`
    scale: 0.5;
    margin: 0;
    margin-block: 4% 0%;
    color: #cecece !important;
    @media (prefers-color-scheme: light) {
        color: #111111 !important;
    }
    @media (max-width: 1200px) {
        scale: 0.7;
    }
    @media (max-width: 768px) {
        scale: 0.6;
    }
    @media (max-width: 576px) {
        scale: 0.7;
    }
    @media (max-width: 440px) {
        scale: 0.6;
    }
`;

const CardSubHeading = styled.p`
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 11px;
    margin-top: 1.5%;
    color: rgba(255, 255, 255, 0.6);
    @media (prefers-color-scheme: light) {
        color: #111111;
    }
    @media (min-width: 768px) {
        font-size: 13px;
        margin-top: 1%;
    }
    @media (min-width: 1200px) {
        font-size: 16px;
        margin-top: 0.05%;
    }
`;

export const ImageCard = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`;

const Image = styled.img`
    width: 80%;
`;

const TextContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 60%;
    @media (min-width: 768px) {
        top: 65%;
    }
    @media (min-width: 1200px) {
        top: 75%;
    }
`;

export default function Features1(props: IProps) {
    const isDarkMode = useIsDarkMode();
    const icon = isDarkMode
        ? props.sectionIcon.iconDark
        : props.sectionIcon.iconLight;
    const authOfflineImage = isDarkMode
        ? props.images[1].fluid.srcSetWebp
        : props.images[0].fluid.srcSetWebp;
    const searchBar = isDarkMode
        ? props.images[3].fluid.srcSetWebp
        : props.images[2].fluid.srcSetWebp;
    const authScreenBlank = isDarkMode
        ? props.images[5].fluid.srcSetWebp
        : props.images[4].fluid.srcSetWebp;
    const authCategory = isDarkMode
        ? props.images[7].fluid.srcSetWebp
        : props.images[6].fluid.srcSetWebp;
    const typingBackgroundColor = isDarkMode ? "#332838" : "#FFFFFF";

    return (
        <Container>
            <IconContainer>{icon}</IconContainer>
            <Heading>and more!</Heading>
            <div className="d-flex justify-content-center">
                <CardContainer>
                    <Card1>
                        <CardHeading>Next code</CardHeading>
                        <CardSubHeading>
                            Save time by viewing your next code
                        </CardSubHeading>
                        <ImageCard style={{ marginTop: "8%" }}>
                            <WidgetCard iconData={widgetCardIcons[0]} />
                        </ImageCard>
                    </Card1>
                    <Card1>
                        <CardHeading>Icons</CardHeading>
                        <CardSubHeading>
                            Easily identify your tokens by their icons
                        </CardSubHeading>
                        <ImageCard style={{ marginTop: "15%" }}>
                            <Carousel />
                        </ImageCard>
                    </Card1>

                    <Card2>
                        <CardHeading>Share</CardHeading>
                        <CardSubHeading>
                            Share codes with your team securely
                        </CardSubHeading>
                        <ImageCard style={{ marginTop: "8%" }}>
                            <ShareCard />
                        </ImageCard>
                    </Card2>
                    <Card2>
                        <CardHeading>Add notes</CardHeading>
                        <CardSubHeading>
                            Save important information about your codes
                        </CardSubHeading>
                        <ImageCard style={{ marginTop: "8%" }}>
                            <NotesCard />
                        </ImageCard>
                    </Card2>

                    <Card3>
                        <CardHeading>Tag</CardHeading>
                        <CardSubHeading>Organize with ease</CardSubHeading>
                        <ImageCard>
                            <Image
                                fetch-priority="high"
                                srcSet={authScreenBlank}
                                alt="auth Home screen"
                                style={{ width: "75%" }}
                            />
                            <WidgetAnimation
                                animationType="tag"
                                imagesrc={authCategory}
                            />
                        </ImageCard>
                    </Card3>
                    <Card3>
                        <CardHeading>Pin</CardHeading>
                        <CardSubHeading>
                            Favorite services to the top
                        </CardSubHeading>
                        <ImageCard>
                            <Image
                                fetch-priority="high"
                                srcSet={authScreenBlank}
                                alt="auth Home screen"
                                style={{ width: "75%" }}
                            />
                            <WidgetAnimation animationType="pin" imagesrc="" />
                        </ImageCard>
                    </Card3>

                    <Card4>
                        <CardHeading>Search</CardHeading>
                        <CardSubHeading>Don't scroll</CardSubHeading>
                        <ImageCard>
                            <Image
                                fetch-priority="high"
                                srcSet={searchBar}
                                alt="search bar"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    scale: "0.95",
                                    marginTop: "3%",
                                }}
                            />
                            <TypingAnimation
                                isPasswordAnimation={false}
                                typingBackgroundColor={typingBackgroundColor}
                            />
                        </ImageCard>
                        <ImageCard style={{ scale: "1.2", marginTop: "7.5%" }}>
                            <WidgetAnimation
                                animationType="search"
                                imagesrc=""
                            />
                        </ImageCard>
                    </Card4>
                    <Card4>
                        <ImageCard>
                            <Image
                                fetch-priority="high"
                                srcSet={authOfflineImage}
                                alt="Auth offline"
                            />
                            <TextContainer>
                                <TextShine text="Use without backups" />
                            </TextContainer>
                        </ImageCard>
                        <div style={{ marginTop: "2%" }}>
                            <CardHeading>Offline mode</CardHeading>
                            <CardSubHeading>
                                Use without an account
                            </CardSubHeading>
                        </div>
                    </Card4>
                </CardContainer>
            </div>
        </Container>
    );
}

import { useIsDarkMode } from "hooks/use-media-query";
import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { LineAnimation, Paragraph, Title } from "./WidgetCard";
import { ArrowPathIcon, LockClosedIcon } from "@heroicons/react/24/solid";

export interface DarkModeProp {
    isDarkMode: boolean;
}

const URLBarItem = styled.span<DarkModeProp>`
    color: ${({ isDarkMode }) => (isDarkMode ? "#ffffff" : "#000000")};
`;

const CardBackground = styled.div<DarkModeProp>`
    position: relative;
    overflow: hidden;
    border-radius: 3%;
    background: ${({ isDarkMode }) => (isDarkMode ? "#181818" : "#F6F6F6")};
    @media (prefers-color-scheme: light) {
        box-shadow:
            0px 3.791px 5.32px 0px rgba(0, 0, 0, 0.09),
            0px 1.577px 2.214px 0px rgba(0, 0, 0, 0.13);
    }
`;

export default function ShareCard() {
    const isDarkMode = useIsDarkMode();
    return (
        <Container
            style={{
                display: "flex",
                flexDirection: "column",
                background: isDarkMode ? "#3B2A45" : "#EFDAFC",
                borderRadius: "10px",
                padding: "16px",
                margin: "0 16%",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Row>
                <Container
                    style={{
                        borderRadius: "4px",
                        background: isDarkMode ? "#000000" : "#ffffff",
                        marginLeft: "12px",
                        marginRight: "12px",
                        alignItems: "center",
                        maxWidth: "calc(100% - 24px)",
                    }}
                >
                    <Row style={{ alignItems: "center" }}>
                        <Col className="col-auto">
                            <URLBarItem isDarkMode={isDarkMode}>
                                <LockClosedIcon
                                    width={16}
                                    style={{ marginBottom: "2px" }}
                                />
                            </URLBarItem>
                        </Col>
                        <Col
                            style={{
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                fontSize: "12px",
                                paddingLeft: "0px",
                            }}
                        >
                            <URLBarItem isDarkMode={isDarkMode}>
                                <span>auth.ente.io</span>
                            </URLBarItem>
                            <span
                                style={{
                                    color: "#646464",
                                }}
                            >
                                /share?data=encry...
                            </span>
                        </Col>
                        <Col className="col-auto">
                            <URLBarItem isDarkMode={isDarkMode}>
                                <ArrowPathIcon
                                    width={16}
                                    style={{ marginBottom: "2px" }}
                                />
                            </URLBarItem>
                        </Col>
                    </Row>
                </Container>
            </Row>
            <Row style={{ marginTop: "24px", width: "90%" }}>
                <CardBackground isDarkMode={isDarkMode}>
                    <LineAnimation style={{ marginLeft: "-8%" }} />

                    <Row style={{ marginTop: "16px", marginBottom: "8px" }}>
                        <Col style={{ flex: "auto", marginTop: "2px" }}>
                            <Title style={{ scale: "1.2", marginLeft: "10%" }}>
                                475 074
                            </Title>
                        </Col>

                        <Col style={{ flex: "auto", textAlign: "right" }}>
                            <Row style={{ justifyContent: "flex-end" }}>
                                <Paragraph isDarkMode={isDarkMode}>
                                    next
                                </Paragraph>
                            </Row>
                            <Row
                                style={{
                                    justifyContent: "flex-end",
                                    marginTop: "4px",
                                }}
                            >
                                <Paragraph isDarkMode={isDarkMode}>
                                    456 789
                                </Paragraph>
                            </Row>
                        </Col>
                    </Row>
                </CardBackground>
            </Row>
        </Container>
    );
}

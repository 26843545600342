import { useIsDarkMode } from "hooks/use-media-query";
import { WidgetIconData } from "images/icons/auth-homepage-icons";
import React from "react";
import styled, { keyframes } from "styled-components";
import TextShine from "./TextShine";

interface IProps {
    iconData: WidgetIconData;
}

export interface DarkModeProp {
    isDarkMode: boolean;
}

interface LogoProps {
    isIconEnte: boolean;
    invertLogoColor: boolean;
}

const Background = styled.div<DarkModeProp>`
    position: relative;
    width: 60%;
    aspect-ratio: 2.73;
    overflow: hidden;
    border-radius: 3%;
    background: ${({ isDarkMode }) => (isDarkMode ? "#181818" : "#F6F6F6")};
    @media (prefers-color-scheme: light) {
        box-shadow:
            0px 3.791px 5.32px 0px rgba(0, 0, 0, 0.09),
            0px 1.577px 2.214px 0px rgba(0, 0, 0, 0.13);
    }
`;

const animateWidth = keyframes`
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
`;

export const LineAnimation = styled.div`
    width: 100%;
    height: 2%;
    background: #970fd6;
    position: absolute;
    animation: ${animateWidth} 30s linear infinite;
`;

const Row = styled.div`
    display: flex;
    width: 90%;
    height: 35%;
    margin-top: 5%;
    margin-left: 5%;
`;

const Column1 = styled.div`
    width: 70%;
`;

const Column2 = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 30%;
`;

export const Title = styled.p`
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    @media (max-width: 1200px) {
        font-size: 16px;
    }
    @media (max-width: 992px) {
        font-size: 14px;
    }
    @media (max-width: 768px) {
        font-size: 10px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
    }
    @media (max-width: 440px) {
        font-size: 12px;
    }
`;

export const Paragraph = styled.p<DarkModeProp>`
    margin: 0;
    margin-top: -2%;
    color: ${({ isDarkMode }) =>
        isDarkMode ? "rgba(255, 255, 255, 0.60)" : "rgba(0, 0, 0, 0.60)"};
    font-weight: 400;
    font-size: 15px;
    @media (max-width: 1200px) {
        font-size: 11px;
    }
    @media (max-width: 992px) {
        font-size: 9px;
    }
    @media (max-width: 768px) {
        font-size: 6px;
    }
    @media (max-width: 576px) {
        font-size: 11px;
    }
    @media (max-width: 440px) {
        font-size: 8px;
    }
`;

export const Logo = styled.div<LogoProps>`
    scale: ${({ isIconEnte }) => (isIconEnte ? "1.1" : "1.2")};
    svg {
        filter: ${({ invertLogoColor }) =>
            invertLogoColor ? "invert(1)" : "none"};
    }
    @media (max-width: 1200px) {
        scale: ${({ isIconEnte }) => (isIconEnte ? "0.9" : "1")};
        margin-right: ${({ isIconEnte }) => (isIconEnte ? "-2%" : "-0%")};
    }
    @media (max-width: 992px) {
        scale: ${({ isIconEnte }) => (isIconEnte ? "0.75" : "0.85")};
        margin-right: ${({ isIconEnte }) => (isIconEnte ? "-5%" : "-2%")};
    }
    @media (max-width: 768px) {
        scale: ${({ isIconEnte }) => (isIconEnte ? "0.55" : "0.65")};
        margin-right: ${({ isIconEnte }) => (isIconEnte ? "-10%" : "-7%")};
    }
    @media (max-width: 576px) {
        scale: ${({ isIconEnte }) => (isIconEnte ? "0.9" : "1")};
        margin-right: ${({ isIconEnte }) => (isIconEnte ? "-2%" : "0%")};
    }
    @media (max-width: 440px) {
        scale: ${({ isIconEnte }) => (isIconEnte ? "0.65" : "0.75")};
        margin-right: ${({ isIconEnte }) => (isIconEnte ? "-6%" : "-2%")};
    }
`;

export const AnimationContainer = styled.div`
    position: absolute;
    transform: translate(-18%, 35%);
    @media (max-width: 1200px) {
        transform: translate(-15%, -13%);
    }
    @media (max-width: 992px) {
        transform: translate(-14%, -14%);
    }
`;

export default function WidgetCard(props: IProps) {
    const isDarkMode = useIsDarkMode();
    const isIconEnte = props.iconData.text === "ente";
    const invertLogoColor = isDarkMode && props.iconData.text === "Github";
    const cardContent = [
        "photos@ente.io",
        "auth@ente.io",
        "475 074",
        "next",
        "456 789",
    ];
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                width: "100%",
                paddingBlock: "0.1%",
            }}
        >
            <Background isDarkMode={isDarkMode}>
                <LineAnimation />

                <Row>
                    <Column1>
                        <Title>{props.iconData.text}</Title>
                        <Paragraph isDarkMode={isDarkMode}>
                            {props.iconData.text === "Ente"
                                ? cardContent[0]
                                : cardContent[1]}
                        </Paragraph>
                    </Column1>

                    <Column2 style={{ alignItems: "center" }}>
                        <Logo
                            isIconEnte={isIconEnte}
                            invertLogoColor={invertLogoColor}
                        >
                            {props.iconData.icon}
                        </Logo>
                    </Column2>
                </Row>

                <Row>
                    <Column1>
                        <Title style={{ scale: "1.2", marginLeft: "10%" }}>
                            {cardContent[2]}
                        </Title>
                    </Column1>

                    <Column2
                        style={{
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "start",
                            marginTop: "-2%",
                        }}
                    >
                        <div>
                            <Paragraph isDarkMode={isDarkMode}>
                                {cardContent[3]}
                            </Paragraph>
                        </div>
                        <div style={{ position: "relative" }}>
                            <AnimationContainer>
                                <TextShine text={cardContent[4]} />
                            </AnimationContainer>
                        </div>
                    </Column2>
                </Row>
            </Background>
        </div>
    );
}
